@import 'styles/utils/mixins';

.container {
  @include flex(column);

  gap: 12px;
  outline: none;

  .mainWrapper {
    @include flex(row, initial, center);

    gap: 12px;

    &.marked {
      .link {
        position: relative;

        &::after {
          content: 'TOP';
          position: absolute;
          left: 110%;
          top: 50%;
          transform: translateY(-50%) rotate(5deg);
          padding: 2px 4px;
          background-color: $yellow500;
          color: $gray900;
          border-radius: 3px;
        }
      }
    }
  }

  .itemsWrapper {
    @include flex(row, flex-start, center);

    gap: 16px;

    @include below-tablet {
      @include flex(column, initial, flex-start);

      padding-left: 44px;
      gap: 8px;
    }
  }

  .itemWrapper {
    @include flex(row, flex-start, center);

    gap: 4px;
  }

  .link,
  .title {
    @include fontSize(16px, 150%);

    margin: 0;
    font-weight: 700;
    color: $gray0;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: $yellow500;
    }
  }

  .itemLink {
    @include fontSize(16px, 150%);

    color: $gray0;

    &:hover {
      color: $yellow500;
    }
  }

  .icon {
    width: 32px;
    height: 32px;
  }

  .itemIcon {
    width: 16px;
    height: 16px;

    path {
      fill: $gray0;
    }
  }

  @include below-tablet {
    margin-top: 16px;
  }
}

.pageScrolling {
  .link,
  .itemLink,
  .title {
    color: $gray900;

    &:hover {
      color: $yellow500;
    }
  }

  .itemIcon {
    path {
      fill: $gray900;
    }
  }
}

.lightTheme {
  .link,
  .itemLink,
  .title {
    color: $gray900;

    &:hover {
      color: $yellow500;
    }
  }

  .itemIcon {
    path {
      fill: $gray900;
    }
  }
}

.about-company .link {
  font-weight: 500;
}

.solutions .itemsWrapper {
  @include flex(column, initial, flex-start);

  padding-left: 44px;
}

.services .itemsWrapper {
  @include flex(column, initial, flex-start);

  padding-left: 44px;
}
